<template>
    <div>
        <o-field label="Start date">
            <o-datepicker v-model="dates[0]" icon="calendar" trap-focus editable :maxDate="dates[1]"></o-datepicker>
        </o-field>
        <o-field label="End date">
            <o-datepicker v-model="dates[1]" icon="calendar" trap-focus editable :minDate="dates[0]"></o-datepicker>
        </o-field>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            modelValue: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        emits: ["update:modelValue"],
        setup(props, context) {
            //const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $moment = inject("$moment");

            const dates = ref([]);

            const CreateModel = () => {
                const model = [$moment(dates.value[0]).utc().startOf('day'), $moment(dates.value[1]).utc().endOf('day')];
                context.emit("update:modelValue", model);
            };

            if (props.modelValue && props.modelValue.length == 2) {
                dates.value = [
                    $moment(props.modelValue[0]).toDate(),
                    $moment(props.modelValue[1]).toDate()
                ];
            }
            else {
                dates.value = [
                    $moment().add(-7, 'days').startOf('day').toDate(),
                    $moment().endOf('day').toDate()
                ];
            }
            CreateModel();

            watch(() => dates.value, (n, o) => {
                if (n) {
                    CreateModel();
                }
            }, { deep: true });



            return {
                dates,
                CreateModel
            };
        }
    }
</script>