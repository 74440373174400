<template>
    <div class="columns">
        <div class="column" v-show="loading">
            <loading></loading>
        </div>
        <div class="column" v-show="!loading">
            <draggable :list="filteredRows" handle=".handle" ghost-class="ghost" :group="name" item-key="Id" @add="Added" @update="UpdateOrder" class="is-flex is-flex-wrap-wrap">
                <template #header>
                    <div></div>
                </template>
                <template #item="{ element }">
                    <div class="card mx-2 my-2 is-flex is-flex-direction-column is-justify-content-space-between">
                        <div class="card-header">
                            <div class="card-header-title">
                                <span class="handle"><o-icon icon="grip-lines"></o-icon></span>
                            </div>
                            <div class="card-header-icon">
                                <span style="color:indianred" v-if="element.IsDeleted">
                                    deleted
                                </span>
                            </div>
                        </div>
                        <div class="card-content">
                            <figure class="image">
                                <image-component :name="element.Src" :alt="element.Name" :size="size"></image-component>
                            </figure>
                            <div class="has-text-centered mt-1">
                                {{element.Name}}
                            </div>
                            <div v-if="name === 'GrilleFinishType'" class="has-text-centered mt-1">

                                ({{MaterialNameFromId(element.MaterialId)}})
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="card-footer-item">
                                <router-link :to="name + 's/' + element.Id"><o-icon icon="edit"></o-icon> Edit</router-link>
                            </div>
                        </div>
                    </div>
                </template>
                <template #footer>
                    <div></div>
                </template>
            </draggable>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import draggable from "vuedraggable";
    import { image } from "@/components";
    export default {
        components: {
            "draggable": draggable,
            "image-component": image
        },
        props: {
            name: {
                type: String,
                default: undefined
            },
            size: {
                type: [String, Number],
                default: 13
            },
            filters: {
                type: Object,
                default: () => {
                    return {
                        hideDeleted: false
                    };
                }
            }
        },
        emits: [],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");
            const $httpGetCached = inject("$httpGetCached");

            const loading = ref(true);
            const rows = ref([]);
            const filteredRows = ref([]);
            const materials = ref([]);

            const _filteredRows = computed(() => {
                var filtered = rows.value.slice();
                if (props.filters.hideDeleted) {
                    filtered = filtered.filter(x => !x.IsDeleted);
                }

                if (props.filters?.props) {
                    for (var prop in props.filters.props) {
                        if (!!props.filters.props[prop] === true) {
                            filtered = filtered.filter(x => x[prop] == props.filters.props[prop]);
                        }
                    }
                }

                return filtered;
            });
            watch(() => _filteredRows.value, (n, o) => {
                filteredRows.value = n;
            });

            const GetReport = async () => {
                loading.value = true;
                try {
                    var response = await $http.get("/api/" + props.name + "/all");
                    rows.value = response.data;
                    loading.value = false;
                    return response;
                }
                catch (err) {
                    loading.value = false;
                    console.error(err);
                    return Promise.reject(err);
                }
            };

            const MaterialNameFromId = (id) => {
                if (materials.value.length === 0) {
                    return "[Loading...]";
                } else {
                    return materials.value.find(e => e.Id === id)?.Name || "Id: " + id;
                }
            };

            const GetMaterials = async () => {
                loading.value = true;
                try {
                    var response = await $httpGetCached("/api/GrilleMaterialType/all");
                    materials.value = response.data;
                    loading.value = false;
                    return response.data;
                }
                catch (err) {
                    loading.value = false;
                    console.error(err);
                    return Promise.reject(err);
                }
            };


            const GetMaterialName = async (id) => {
                loading.value = true;
                try {
                    var response = await $httpGetCached("/api/GrilleMaterialType/" + id);
                    loading.value = false;
                    return response.data.Name;
                }
                catch (err) {
                    loading.value = false;
                    console.error(err);
                    return Promise.reject(err);
                }
            };

            const AdjustOrder = () => {
                rows.value.map((x, i) => { x.Order = i; return x });
            }

            const MoveElement = (oldIndex, newIndex) => {
                const movingRight = newIndex > oldIndex;
                const item = filteredRows.value[newIndex];
                const relativeItem = movingRight ? filteredRows.value[newIndex - 1] : filteredRows.value[newIndex + 1];

                const i = rows.value.map(x => x.Id).indexOf(item.Id);
                const j = rows.value.map(x => x.Id).indexOf(relativeItem.Id);
                const offset = j - i;
                rows.value.splice(i, 1);
                rows.value.splice(i + offset, 0, item);
            }

            const UpdateOrder = async (e) => {
                MoveElement(e.oldDraggableIndex, e.newDraggableIndex);
                AdjustOrder();

                try {
                    return await $http.post("/api/" + props.name + "/order", { list: rows.value.map(row => { return { Id: row.Id, Order: row.Order } }) });
                }
                catch (err) {
                    console.error(err);
                    return Promise.reject(err);
                }

            };

            const Added = async (e) => {
                const oldIndex = e.oldIndex - 1;
                const newIndex = e.newIndex - 1;

                AdjustOrder();
            }

            onMounted(() => {
                GetReport();
                GetMaterials();
            });

            return {
                loading,
                rows,
                filteredRows,
                MaterialNameFromId,
                UpdateOrder,
                Added
            };
        }
    }

</script>
